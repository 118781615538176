* {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
}

@font-face {
    font-family: "Open Sans";
    /*src: url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');*/
    src: url("fonts/OpenSans-Regular.ttf") format("ttf");
    /*url("fonts/OpenSans-Regular-webfont.woff2") format("woff2");*/
}

:root {
    --canopia-color-1: #adad2f; /* rgb(173, 173, 47) */
    --canopia-color-2: #1D416D; /* Conser blue on conser.ch rgb(29, 65, 109) */
    --canopia-color-3: #53565a; /* Fundo dark grey */
    --canopia-color-3b: 74, 74, 74;
    --canopia-color-4: #909090;
    --canopia-color-5: #c3c3c3;
    --canopia-color-6: #c04040;
    --canopia-color-climate-pos: #87c940; /*97d700;*/
    --canopia-color-net-zero-aligned: #59C386;
    --canopia-color-climate-neg: #cf152d; /*#a8003e*/
    --canopia-color-thematic-bonds: #87c940;
    /*--canopia-bg-color-1: #efefef;*/
    --canopia-bg-color-1: #e8ecf0; /* Tints of Conser blue: https://www.color-hex.com/color/1d416d */
    --canopia-bg-color-2: #ffffff;
    --canopia-bg-color-3: #f0f0f0;
    --canopia-bg-color-4: #2d65a9; /* Monochromatic Colors of Conser blue: https://www.color-hex.com/color/1d416d */
    --canopia-border-color: #bbc6d3; /* Tints of Conser blue: https://www.color-hex.com/color/1d416d */
    --canopia-tr-hover-bg-color: rgba(0, 0, 256, 0.00001); /* Base: Conser blue */
    --canopia-color-coverage-high: #00b140;
    --canopia-color-coverage-medium: #ffd300;
    --canopia-color-coverage-low: #fe5000;
    /*--canopia-tr-hover-bg-color: rgba(173, 173, 47, 0.2);  !* Base: Conser green *!*/
    /* A+ 00b140 */
    /* A  97d700 */
    /* B  ffd100 */
    /* C  fe5000 */
    /* D  c8102e */
    /* NR 99999b */
}

body {
    overflow-y: scroll; /* Always show vertical scrollbar */
}

.frm-body {
    max-width: 2000px;
    margin: 20px auto;
    min-height: 95vh; /* 95% of the viewport height so that the footer is not in the middle of the screen */
}

.frm-body2 {
    margin: 0 40px;
}

.logo-canopia {
    width: 240px;
    height: 56px;
    margin: 17px 0 17px 40px;
    background-image: url(../images/logo-canopia.svg);
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    float: right;
}

.logo-conser-by-canopia {
    margin: 0 0 5px 40px;

    height: 90px;

    /*width: 243px;*/
    /*background-image: url(../images/logo_conser_by_canopia2.png);*/
    width: 235px;
    /*background-image: url(../images/logo_conser_by_canopia3.png);*/
    background-image: url(../images/logo_conser_by_canopia4.svg);
    /*width: 251px;*/
    /*background-image: url(../images/logo_conser_by_canopia4.png);*/

    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    float: right;
}

.dashboard {
    width: 100%;
    padding-top: 30px; /* height of the logo */
}

.main-content {
    width: 100%;
    padding-top: 120px; /* height of the logo */
}

.canopia-modal-content-fullscreen {
    margin: auto;
    width: 25%;
}

.canopia-modal-content {
    width: 100%;
    padding: 2vh 2vw;
}

.frm-footer {
    width: 100%;
    /*padding: 10rem;*/
    margin-bottom: 30rem; /* space for tooltips */
    /*background-color: var(--canopia-color-2);*/
    /*color: #ffffff;*/
}

.frm-footer-p {
    font-size: x-large;
}

.terms-and-cond-link {
    color: #ffffff;
    font-size: x-large;
    cursor: pointer;
}

.terms-and-cond-title {
    color: var(--canopia-color-1);
    font-size: x-large;
}

.terms-and-cond {
    color: var(--canopia-color-1);
    font-size: large;
}

/* width */
::-webkit-scrollbar {
    width: .9rem;
    height: .6rem;
}

/* Track */
::-webkit-scrollbar-track {
    /*background: rgba(29, 65, 109, 0.36);*/
    background: var(--canopia-bg-color-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
    /*background: var(--canopia-color-1);*/
    /*background: rgba(29, 65, 109, 0.36);*/
    background: rgba(173, 173, 47, 0.5);
    border-radius: .3rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--canopia-color-1);
}

/* Hide the eye to reveal passwords */
::-ms-reveal {
    display: none;
}

.canopia {
    color: var(--canopia-color-1);
}

.canopia2 {
    /*color: #686c87;*/
    color: var(--canopia-color-2);
}

.canopia3 {
    color: var(--canopia-color-3);
}

.canopia6 {
    color: var(--canopia-color-6);
}

.bg-canopia3 {
    background-color: var(--canopia-color-2);
}

.font-size-small {
    font-size: 11px;
}

.client-title {
    font-size: 2.5rem;
    font-weight: 520;
    color: var(--canopia-color-2);
}

.canopia-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.35rem 1rem;
    clear: both;
    text-decoration: none;
    color: var(--canopia-color-2);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

a.canopia-dropdown-item:hover {
    background-color: var(--canopia-bg-color-1);
    /*border-bottom: 2px solid;*/
    /*border-color: var(--canopia-color-2);*/
    color: var(--canopia-color-2);
    text-decoration: none;
}

/* CLIENT DROPDOWN */
.btn-client-dropdown {
    font-size: 1.8rem;
    font-weight: 520;
    color: var(--canopia-color-2);
    border: 1px solid var(--canopia-border-color);
}

.btn-client-dropdown:hover {
    color: var(--canopia-color-2);
    background-color: var(--canopia-bg-color-1);
    border: 1px solid var(--canopia-border-color);
}

/* The little arrow next to the client name in the dropdown title */
.btn-client-dropdown::after {
    color: var(--canopia-color-2);
}

/* OTHER DROPDOWN */
.btn-default-dropdown {
    color: var(--canopia-color-2);
    border: 1px solid var(--canopia-border-color);
    background-color: white;
}

.btn-default-dropdown:hover {
    color: var(--canopia-color-2);
    /*background-color: var(--canopia-bg-color-1);*/
    background-color: white;
    border: 1px solid var(--canopia-color-2);
}

.btn-default-dropdown::after {
    color: var(--canopia-color-2);
}

.btn-primary {
    background-color: var(--canopia-color-2);
    border: 1px solid var(--canopia-color-2);
}

.btn-primary:hover {
    /*color: var(--canopia-color-2);*/
    background-color: var(--canopia-bg-color-4);
    border: 1px solid var(--canopia-color-2);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: var(--canopia-border-color);
    border-color: var(--canopia-border-color);
}

.nowrap {
    white-space: nowrap;
}

.table {
    margin-bottom: 0;
}

/* Overridden (bootstrap) */
.table > thead > tr > th {
    padding: 0.75rem 0.75rem;
    vertical-align: middle;
    border-top: 1px solid var(--canopia-border-color);
    border-bottom: none;
}

/* form controls are bigger than text so has a specific padding */
.table > thead > tr > th.form-padding {
    padding: 0.2rem 0.75rem;
}

/* Overridden (bootstrap) */
.table > :not(:first-child) {
    border-top: none;
}

.table > tbody > tr > td {
    padding: 0.75rem 0.75rem;
    vertical-align: middle;
    border-top: 1px solid var(--canopia-border-color);
    border-bottom: none;
}

.table > tbody > tr > td.form-padding {
    padding: 0.2rem 0.75rem;
}

/* override */
.table thead th {
    vertical-align: bottom;
    border-bottom: 0 none;
}

.table-hover tbody tr:hover > td {
    color: var(--canopia-color-2) !important;
    /*font-weight: bold;*/
    background-color: var(--canopia-tr-hover-bg-color) !important;
}

/* Show the "Expand All" icon */
.table-hover tbody tr:hover td span.label-hover {
    opacity: 1 !important;
}

/* Overridden (bootstrap) */
/*.table-striped tbody tr:nth-of-type(odd) {*/
/*    background-color: #ffffff;*/
/*}*/

.table.portfolio {
    border-spacing: 0;
    border-collapse: separate;
}

.table.portfolio > tbody > tr {
    /*line-height: 28px; !* must be 4px bigger than the height of the ESG icons to keep the same height for the whole table *!*/
}

.table-valign-top td, .table-valign-top td * {
    vertical-align: top;
}

/* Scroll on rows, does not work well */
/*table tbody { display:block; max-height:450px; overflow-y:scroll; }*/
/*table thead, table tbody tr { display:table; width:100%; table-layout:fixed; }*/

th.no-border {
    border-top: 0 none;
}

.table.sticky-thc > thead {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

th.border-round-right {
    border-radius: .25rem .25rem 0 0;
    border-top: 1px solid var(--canopia-border-color);
    border-right: 1px solid var(--canopia-border-color);
}

.canopia-border-left {
    border-top: 1px solid var(--canopia-border-color);
    border-left: 1px solid var(--canopia-border-color);
}

th.border-large-left {
    border-top: 1px solid var(--canopia-border-color);
    /*border-left: 3px solid var(--canopia-border-color);*/
    /*border-left: 1px solid var(--canopia-color-3);*/
    border-left: 1px solid var(--canopia-border-color);
}

td.border-large-left {
    border-top: 1px solid var(--canopia-border-color);
    /*border-left: 3px solid var(--canopia-border-color);*/
    /*border-left: 1px solid var(--canopia-color-3);*/
    border-left: 1px solid var(--canopia-border-color);
}

.total-row {
    font-weight: bold;
    /*color: var(--canopia-color-2);*/
}

.tr-hidden {
    visibility: collapse;
    display: none; /* For Safari who does not seem to support collapse */
}

.td-depth-1 {
}

.td-depth-2 {
    padding-left: 16px;
}

.td-depth-3 {
    padding-left: 32px;
}

.td-depth-4 {
    padding-left: 48px;
}

.td-depth-5 {
    padding-left: 64px;
}

.td-depth-6 {
    padding-left: 80px;
}

.td-depth-7 {
    padding-left: 96px;
}

.td-depth-8 {
    padding-left: 112px;
}

.bg-navbar {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: var(--canopia-color-2);
}

.bm-background-class {
    background-color: var(--canopia-bg-color-1);
}

.canopia-navbar-brand {
    /*color: #fff;*/
    color: var(--canopia-color-1);
    font-size: x-large;
}

.canopia-navbar-brand:hover {
    color: var(--canopia-color-1);
    opacity: 1;
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: 2px solid;
    border-color: var(--canopia-color-1);
}

.canopia-navbar-link {
    color: #fff;
    font-size: medium;
}

.canopia-navbar-link:hover {
    color: white;
    opacity: 1;
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: 2px solid;
    border-color: white;
}

.canopia-navbar-text {
    color: var(--canopia-color-1);
    font-size: medium;
    padding-right: .5rem;
}

/* The little arrow next to a NavDropdown*/
.dropdown-toggle.nav-link::after {
    color: white;
}

.canopia-navdd-link {
    color: var(--canopia-color-2);
    font-size: medium;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("../images/toggle-icon.svg") !important;
}

/* consensus legend */
.consensus-icon-pf {
    background-color: transparent;
    border: 6px solid #1D416D;
    border-radius: 10px;
    vertical-align: middle;
}

.consensus-icon-sub-pf {
    background-color: transparent;
    border: 6px solid #48AFCE;
    border-radius: 10px;
    vertical-align: middle;
}

.consensus-icon-bm {
    background-color: transparent;
    border: 6px solid #a0a0a0;
    border-radius: 10px;
    vertical-align: middle;
}

/* coverage */
.coverage-high {
    background-color: transparent;
    /*border: 2px solid var(--canopia-color-coverage-high);*/
    border: 6px solid rgba(29, 65, 109, 0.7);
    border-radius: 10px;
}

.coverage-medium {
    background-color: transparent;
    /*border: 2px solid var(--canopia-color-coverage-medium);*/
    border: 3px solid rgba(29, 65, 109, 0.7);
    border-radius: 10px;
}

.coverage-low {
    background-color: transparent;
    /*border: 2px solid var(--canopia-color-coverage-low); !* #c8102e;*!*/
    border: 1px solid rgba(29, 65, 109, 0.7);
    border-radius: 10px;
}

.coverage-none {
    background-color: transparent;
    border: 2px solid var(--canopia-color-4); /* #c8102e;*/
    border-radius: 10px;
}

.suitability-ok {
    color: var(--canopia-color-coverage-high);
    /*color: var(--canopia-color-2);*/
}

.suitability-warning {
    color: var(--canopia-color-coverage-low);
    /*color: var(--canopia-color-2);*/
}

/* Overridden (bootstrap) */
.progress {
    border: 1px solid var(--canopia-color-1);
    border-radius: .5rem;
}

.bg-thematic-bonds-pos {
    background-color: var(--canopia-color-thematic-bonds);
}

.bg-climate-pos {
    background-color: var(--canopia-color-climate-pos);
}

.bg-climate-neg {
    background-color: var(--canopia-color-climate-neg);
}

.bg-aligned {
    background-color: var(--canopia-color-net-zero-aligned);
}

.bg-aligned-partial {
    background-color: white;
    border-right: 1px solid var(--canopia-color-net-zero-aligned);
    border-top: 1px solid var(--canopia-color-net-zero-aligned);
    border-bottom: 1px solid var(--canopia-color-net-zero-aligned);
}

.bg-misaligned {
    background-color: var(--canopia-color-climate-neg);
}

.bg-misaligned-partial {
    background-color: white;
    border-right: 1px solid var(--canopia-color-climate-neg);
    border-top: 1px solid var(--canopia-color-climate-neg);
    border-bottom: 1px solid var(--canopia-color-climate-neg);
}

.bg-coverage-bar {
    background-color: var(--canopia-color-5);
}

.progress-canopia {
    /*border-top: 1px solid var(--canopia-color-1);*/
    /*border-right: 1px solid black;*/
    /*border-radius: 0 .3rem 0 0;*/
    display: flex;
    height: 8px;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
}

.progress-canopia-bar {
    border-radius: 0 .3rem .3rem 0;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
}

/* To add a x-axis*/
/*.progress-climate-bm {*/
/*    !*border-right: 1px solid black;*!*/
/*    !*border-bottom: 1px solid var(--canopia-color-2);*!*/
/*    !*border-radius: 0 0 .3rem 0;*!*/
/*    display: flex;*/
/*    height: 8px;*/
/*    overflow: hidden;*/
/*    line-height: 0;*/
/*    font-size: .75rem;*/
/*}*/

/*.progress-climate-bm-bar {*/
/*    border-radius: 0 .3rem .3rem 0;*/
/*    display: flex;*/
/*    -ms-flex-direction: column;*/
/*    flex-direction: column;*/
/*    -ms-flex-pack: center;*/
/*    justify-content: center;*/
/*    overflow: hidden;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    white-space: nowrap;*/
/*    transition: width .6s ease;*/
/*}*/

.progress-canopia-left {
    /*border-left: 3px solid black;*/
    /*border-bottom: 1px solid black;*/
    display: flex;
    height: 8px;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
}

.progress-canopia-left-bar {
    border-radius: 0;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
}

.bg-co2 {
    background-color: var(--canopia-color-2);
}

.bg-co2-bm {
    background-color: rgba(var(--canopia-color-3b), 0.4);
}

.bg-co2-very-good {
    background-color: forestgreen;
}

.bg-co2-good {
    background-color: darkseagreen;
}

.bg-co2-very-bad {
    background-color: red;
}

.bg-co2-bad {
    background-color: palevioletred;
}

.bg-co2-transparent {
    background-color: rgba(29, 65, 109, 0.3);
}

.bg-co2-bm-transparent {
    background-color: rgba(144, 144, 144, 0.3);
}

.bg-truncated {
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.bg-climate-neg-transition {
    background-color: white;
    border-right: 1px solid var(--canopia-color-climate-neg);
    border-top: 1px solid var(--canopia-color-climate-neg);
    border-bottom: 1px solid var(--canopia-color-climate-neg);
}

.bg-co2-bm-transition {
    background-color: white;
    border-right: 1px solid rgba(var(--canopia-color-3b), 0.4);
    border-top: 1px solid rgba(var(--canopia-color-3b), 0.4);
    border-bottom: 1px solid rgba(var(--canopia-color-3b), 0.4);
}

.climate-icon-plus {
    position: relative;
    top: -7px;
    z-index: -1;
}

.aligned {
    display: inline-block;
    color: white;
    font-weight: bold;
    padding: 0 8px 0 8px;
    background-color: var(--canopia-color-net-zero-aligned);
    border: 1px solid var(--canopia-color-net-zero-aligned);
    border-radius: .8rem;
}

.aligned-partial {
    display: inline-block;
    color: var(--canopia-color-net-zero-aligned);
    font-weight: bold;
    padding: 0 8px 0 8px;
    background-color: white;
    border: 1px solid var(--canopia-color-net-zero-aligned);
    border-radius: .8rem;
}

.misaligned {
    display: inline-block;
    color: white;
    font-weight: bold;
    padding: 0 8px 0 8px;
    background-color: var(--canopia-color-climate-neg);
    border: 1px solid var(--canopia-color-climate-neg);
    border-radius: .8rem;
}

.misaligned-partial {
    display: inline-block;
    color: var(--canopia-color-climate-neg);
    font-weight: bold;
    padding: 0 8px 0 8px;
    background-color: white;
    border: 1px solid var(--canopia-color-climate-neg);
    border-radius: .8rem;
}

.focus-hidden {
    pointer-events: none;
    /*opacity: 0.2;*/
    display: none;
    /*visibility: hidden;*/
}

.search_highlight {
    /*background-color: #f0f000;*/
    /*background-color: rgba(173, 173, 47, 0.5);*/
    background-color: #e0e030;
    /*background-color: var(--canopia-color-1);*/
    /*color:var(--canopia-color-2);*/
    /*border: 2px solid var(--canopia-color-1);*/
    /*border-bottom: 2px solid var(--canopia-color-1);*/
}

/* Overridden (bootstrap) */
.breadcrumb {
    margin-bottom: 0;
    background-color: #f8f9fa;
}

/* Overridden (bootstrap) */
.panel {
    background-color: transparent;
}

/* Overridden (bootstrap) */
.panel-info {
    border-color: #337AB7;
}

.chart-rating {
    width: 350px;
    height: 150px;
}

.chart-cons-rating {
    /*min-width: 500px;*/
    width: 90%;
    height: 130px;
}

.chart-consensus-xy {
    width: 360px;
    height: 242px;
    margin-left: 0;
}

.chart-carbon-trajectory {
    width: 95%;
    min-height: 350px;
    height: 50vh;
}

.chart-detail2 {
    /*width: 95%;*/
    height: 68vh;
}

.chart-sector-all {
    width: 100%;
    min-width: 950px;
    min-height: 250px;
    height: 25vh;
}

.chart-sector-single {
    margin: auto;
    width: 300px;
    min-height: 250px;
    height: 25vh;
}

.chart-sector-sub-bar {
    /*width: 50%;*/
    /*height: 250px;*/
    height: 30vh;
}

.chart-sector-sub-pie {
    /*margin: auto;*/
    width: 100%;
    min-height: 200px;
    height: 25vh;
}

.chart-consensus-sub-pie {
    margin: auto;
    width: 740px;
    height: 340px;
}

.chart-sector-single-histo {
    min-height: 250px;
    height: 25vh;
}

.chart-sector-histo {
    min-height: 250px;
    height: 40vh;
}

.chart-impact {
    width: 95%;
    /*height: 300px;*/
    height: 30vh;
}

.position-detail {
    width: 50%;
}

.bg-placeholder-top-pos {
    background-color: var(--canopia-color-2);
}

.width-25 {
    width: 33%;
    min-width: 36rem;
}

.width-54 {
    width: 50%;
    min-width: 54rem;
}

.width-full {
    width: 100%;
}

.bm-display {
    display: table-cell;
}

.bm-hidden {
    display: none;
}

.nav-tabs {
    border-bottom: none;
    /*display: inline-flex !important;*/
}

.detail-window {
    height: 65vh;
    overflow-y: auto;
}

p.detail-title-font, p.detail-title-font * {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0;
}

p.detail-title-font > span.label-hover:hover {
    border-bottom: 2px solid;
}

.detail-title {
    /*margin: 20px 0;*/
    padding: 0.75rem;
    background-color: var(--canopia-bg-color-1);
    border-top: 1px solid var(--canopia-border-color);
    border-bottom: none;
    /*border-left: 3px solid var(--canopia-color-2);*/
    /*border-top: 1px solid var(--canopia-border-color);*/
}

.detail-sub-title {
    padding: 0.75rem;
    border-top: 1px solid var(--canopia-border-color);
    border-bottom: none;
    /*background-color: var(--canopia-bg-color-1);*/
    /*border-left: 3px solid var(--canopia-color-2);*/
    /*border-top: 1px solid var(--canopia-color-2);*/
}

.nav-tabs .nav-link.active {
    color: var(--canopia-color-1);
    background-color: var(--canopia-bg-color-1);
    border: 1px solid var(--canopia-border-color);
}

.nav-tabs .nav-link {
    color: var(--canopia-color-2);
    font-size: 16px;
    font-weight: bold;
    background-color: var(--canopia-bg-color-2);
    border: 1px solid var(--canopia-border-color);
    border-radius: .25rem .25rem 0 0;
}

.nav-tabs .nav-link:hover {
    background-color: var(--canopia-bg-color-1);
    border: 1px solid var(--canopia-border-color);
}

.std-link {
    color: var(--canopia-color-2);
}

/* Add a scroll in the menu dropdown */
.dropdown-menu {
    max-height: 40vh;
    overflow-y: scroll;
}

.dropdown-menu.show {
    display: block;
    /*border-color: var(--canopia-color-2);*/
    border: 1px solid var(--canopia-border-color);
    opacity: 1;
}

.filter-box {
    /* Add a scroll in the filter categories */
    max-height: 40vh;
    overflow-y: auto;
}

span[data-descr] {

}

span[label]::before {
    content: attr(label);
}

.background-light {
    background-color: var(--canopia-bg-color-1) !important;
}

.background-pf-light {
    background-color: var(--canopia-bg-color-2);
}

.background-bm-light {
    background-color: var(--canopia-bg-color-3);
}

h5.canopia-nav-link, span.canopia-nav-link {
    opacity: 0.5;
    padding-bottom: 3px;
    border-bottom: 0;
}

h5.canopia-nav-link:hover, span.canopia-nav-link:hover {
    color: var(--canopia-color-2);
    opacity: 1;
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: 2px solid;
    border-color: var(--canopia-color-2);
}

h5.canopia-nav-link-active, span.canopia-nav-link-active {
    color: var(--canopia-color-1);
    padding-bottom: 3px;
    border-bottom: 2px solid;
    border-color: var(--canopia-color-2);
}

span.canopia-label-with-legend {
    padding-bottom: 3px;
    border-color: var(--canopia-color-2);
    border-bottom: 1px dotted;
    cursor: help;
}

span.canopia-icon-with-legend {
    padding-bottom: 3px;
    cursor: pointer;
}

.form-group {
    padding: 0;
    margin-bottom: 0;
}

.form-control:focus {
    /*border-color: var(--canopia-color-2);*/
    /*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(47, 82, 143, 0.5);*/

    box-shadow: 0 0 5px rgba(47, 82, 143, 0.5);
    border: 1px solid rgba(47, 82, 143, 0.5);
}

.form-check-input {
    position: relative;
    margin: 0 0 0.1rem 0;
    left: -6px;
    vertical-align: middle;
}

.form-check-label {
    /*left: -20px;*/
    position: relative;
}

.custom-select:focus {
    /*border-color: var(--canopia-color-2);*/
    /*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(47, 82, 143, 0.5);*/

    box-shadow: 0 0 5px rgba(47, 82, 143, 0.5);
    border: 1px solid rgba(47, 82, 143, 0.5);
}

span.label-hover, a.label-hover:hover {
    /*text-decoration: underline;*/
    cursor: pointer;
}

span.chart-label-hover:hover {
    padding-bottom: 1px;
    border-bottom: 2px solid var(--canopia-color-2);
    color: var(--canopia-color-2);
    cursor: pointer;
}

.category-removed {
    /*text-decoration: line-through;*/
    color: var(--canopia-color-4);
}

/* Tooltips */
/* Overridden (bootstrap)*/
.tooltip-inner {
    padding: 0.5rem 1rem;
    background-color: var(--canopia-bg-color-1);
    color: var(--canopia-color-3);
    text-align: left;
    border-style: solid;
    border-width: thin;
    border-color: var(--canopia-color-2);
    max-width: 500px;
}

/* Tooltip */
.tooltip.show {
    opacity: 1;
}

/* Tooltips arrow */
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: var(--canopia-color-2) !important;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: var(--canopia-color-2) !important;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: var(--canopia-color-2) !important;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: var(--canopia-color-2) !important;
}

.icon-tooltip {
    font-size: 11px;
}

.lookthrough-warning {
    color: #ffd300;
}

.lookthrough-error {
    color: #e35521;
}

.consensus-shape {
    border: none;
    /*border-style: solid;*/
    /*border-width: 2px;*/
    /*border-color: #3c3c3c;*/
    height: 15px;
    width: 15px;
    border-radius: 2px;
}

.esg-grade-ah {
    background-color: #11b141;
    color: #ffffff;
    border-style: solid;
    border-width: thin;
    border-color: #3c3c3c;
    width: 28px;
    border-radius: 10px;
}

.esg-grade-a {
    background-color: #71C822;
    color: #ffffff;
    /*border-style: solid;*/
    /*border-width: thin;*/
    /*border-color: #3c3c3c;*/
    width: 28px;
    border-radius: 10px;
}

.esg-grade-b {
    background-color: #ffd300;
    color: #ffffff;
    /*border-style: solid;*/
    /*border-width: thin;*/
    /*border-color: #3c3c3c;*/
    width: 28px;
    border-radius: 10px;
}

.esg-grade-c {
    background-color: #e35521;
    color: #ffffff;
    /*border-style: solid;*/
    /*border-width: thin;*/
    /*border-color: #3c3c3c;*/
    width: 28px;
    border-radius: 10px;
}

.esg-grade-d {
    background-color: #a8003e;
    color: #ffffff;
    /*border-style: solid;*/
    /*border-width: thin;*/
    /*border-color: #3c3c3c;*/
    width: 28px;
    border-radius: 10px;
}

.esg-grade-nr {
    background-color: #a0a0a0;
    color: #ffffff;
    border-style: solid;
    border-width: thin;
    border-color: #3c3c3c;
    width: 28px;
    border-radius: 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

/* Overridden (bootstrap) */
.panel-body {
    padding: .5em .7em;
}

.removable {
}

.panel-form {
    padding: 2em 1em 1em 2em;
}

.float-left {
    float: left;
}

.clear-left {
    clear: left;
}

.float-right {
    float: right;
}

.modal-90w {
    width: 90vw !important;
}

.modal-error {
    width: 60% !important;
}

.modal-body-error {
    height: 80% !important;
    overflow-y: scroll !important;
}

.canopia-info {
    margin-top: 15px;
    padding: 8px;
    border: 1px solid var(--canopia-border-color);
    border-radius: 8px;
}

.import_log_WARN {
    padding: 3px 8px;
    background-color: #ffdf7e;
}

.import_log_ERROR {
    padding: 3px 8px;
    background-color: #ff8080;
}

.import_log_INFO {
    padding: 0 8px;
    background-color: #CFF4FC;
}

.import_log {
    margin-top: 15px;
    border: 1px solid var(--canopia-border-color);
    max-height: 40vh;
    overflow-y: scroll;
}

.box-light {
    border: 1px solid var(--canopia-border-color);
    border-radius: 4px;
    padding: 3px 6px;
}

.esg_review_detail_container {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between; !* Adjust as needed for horizontal alignment *!*/
}

.esg_review_detail_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*justify-content: space-between; !* Adjust as needed for vertical alignment *!*/
    /*margin-bottom: 20px; !* Space between rows *!*/
}

.esg_review_detail_final_grade {
    width: 25%;
    /*height: 100px;*/
    padding: 10px;
}

.esg_review_detail_chart {
    width: 64%;
    /*height: 100px;*/
    padding: 10px;
    border-left: 1px solid var(--canopia-border-color);
    border-bottom: none;
}

.esg_review_detail_coverage {
    width: 11%;
    /*height: 100px;*/
    vertical-align: top;
    padding: 10px;
    border-left: 1px solid var(--canopia-border-color);
}

svg.consensus-grade-icon tspan {
    font-size: 320px;
}

.consensus-column-chart {
    text-align: center;
    overflow: hidden;
    padding: 5px 0;
    margin-right: 2px;
    border-radius: .25rem;
}

.histo-single-field {
    /*min-width: 220px; !* final grade + trend + coverage below (90 + 70 + 60) *!*/
    /*width: 17%;*/
}

.histo-esg-review-90 {
    min-width: 90px;
    width: 7%;
}

.histo-esg-review-60 {
    min-width: 60px;
    width: 4.5%;
}

.histo-esg-review-70 {
    min-width: 70px;
    width: 5.5%;
}

.pf-esg-review-90 {
    width: 90px;
}

.pf-esg-review-70 {
    width: 70px;
}

.pf-esg-review-60 {
    width: 60px;
}

.esg-bars-long {
    min-width: 200px;
}

.esg-bars-short {
    min-width: 110px;
}

.hidden {
    visibility: hidden;
}

.dnl-row:hover {
    background-color: var(--canopia-bg-color-1) !important;
}

.dnl-row:hover div.label-hover {
    background-color: var(--canopia-bg-color-1) !important;
    opacity: 1 !important;
}

.setup-form-row {
    display: flex;
    flex-wrap: nowrap;
    margin: .75rem 0 .5rem .5rem;
}

.setup-box {
    display: flex;
    flex-wrap: wrap;
    margin: .75rem 0 .5rem .5rem;
}

.setup-form-label {
    padding: .75em .5em 0 0;
    width: 10vw;
}

.setup-form-input {
    padding: .5em .5em 0 0;
    width: 30vw;
}

.setup-th {
    padding: 0.75em 0.5em;
    flex-shrink: 0;
    flex-grow: 0;
}

.setup-row {
    display: flex;
    flex-wrap: nowrap;
    border-top: 1px solid var(--canopia-border-color);
}

/* Show the "Expand all" icon */
.setup-row:hover span.label-hover {
    opacity: 1 !important;
    cursor: pointer;
}

.setup-th:hover span.label-hover {
    opacity: 1 !important;
    cursor: pointer;
}

.setup-col {
    flex-shrink: 0;
    flex-grow: 0;
}
.setup-row:hover .setup-col {
    color: #1D416D !important;
    opacity: 1 !important;
    /*background-color: var(--canopia-tr-hover-bg-color) !important;*/
    background-color: #ECECEC !important;
}

.gc-status {
    padding: 0.2em 0.5em;
    display: inline-block;
    border-radius: 1rem;
}

.gc-duplicates {
    background-color: #fdefb2;
    padding: 0.2em 0.5em;
    display: inline-block;
    border-radius: 1rem;
    /*border: 2px solid #b0b020;*/
}

.no-change {
    /*border: 2px solid #20b082;*/
    /*background-color: #b0f0e2;*/
}

.new {
    /*border: 2px solid #0a53be;*/
    background-color: #d0d0ff;
}

.removed {
    /*border: 2px solid #cf152d;*/
    background-color: #ffd0d0;
}

.name-change {
    background-color: #fdefb2;
}

.global-param-modified {
    background-color: #fdefb2;
}